<template>
  <main v-if="data.profile">
    <h1>
      <span>Share your </span><br>
      <mark>#</mark>
      <span>mySDCstack masterpiece!</span>
    </h1>
    <div class="card__wrap">
      <!--      <card-module ref="card" :blob="data.blob.finished" :nickname="data.nickname" :message="data.message"-->
      <!--                   :url="data.url"/>-->
      <img :src="data.profile.imageStickerUrl" alt="" class="card"/>
    </div>
    <a :href="data.download" class="download" type="application/octet-stream" :download="`${data.profile.nickname}.png`">
      Download Image
      <img src="@/assets/icon-download.svg" alt="">
    </a>
    <div class="share">
      <a :href="`http://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(data.profile.shareUrl)}`" target="_blank">
        <img src="@/assets/icon-facebook.svg" alt="">
        <span>Facebook</span>
      </a>
      <a :href="`https://twitter.com/intent/tweet?text=I%20just%20made%20my%20own%20photo%20profile%20for%20%23SDC21%20with%20%23mySDCstack!%20Check%20it%20out%20%3A&url=${encodeURIComponent(data.profile.shareUrl)}`" target="_blank">
        <img src="@/assets/icon-twitter.svg" alt="">
        <span>Twitter</span>
      </a>
      <a :href="`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(data.profile.shareUrl)}`" target="_blank">
        <img src="@/assets/icon-linkedin.svg" alt="">
        <span>Linkedin</span>
      </a>
    </div>
  </main>
  <footer>
    <h1>#mySDCstack Giveaway</h1>
    <h2>[How to participate]</h2>
    <p>
      Agree to the terms and share your SDC stack with the required hashtags on social media (Twitter, Instagram,
      Facebook or LinkedIn)
      Required hashtags: <strong>#mySDCstack #SDC21 #Samsung</strong> + add your developer type:
      #TheWiseWizard#TheMightyMaster,
      #TheValiantChampion, #TheRadicalRebel, #TheMadScientist
    </p>
    <a href="/">Go to main</a>
  </footer>
</template>

<script>
import {computed, onBeforeMount, reactive, ref} from "vue";
import {useStore} from '@/helper/vue'
import {useRoute} from 'vue-router'
import router from "@/router";
import cardModule from '@/modules/card.vue'

export default {
  components: {cardModule},
  setup() {
    const {state} = useStore()
    const {params} = useRoute()
    const card = ref(null)
    const dummyCanvas = ref(null)
    const data = reactive({
      // nickname: computed(() => state.nickname),
      // message: computed(() => state.message),
      // stickers: computed(() => state.currentStickers),
      // url: ref(params.url),
      blob: computed(() => state.takeImage),
      profile: computed(() => state.profile),
      download: computed(()=> URL.createObjectURL(data.blob.finished))
    })
    const download = () => {
    }
    onBeforeMount(async () => {
      if (!state.takeImage) await router.replace({name: 'create'})
    })
    return {
      data,
      download,
      card,
      dummyCanvas
    }
  }
}
</script>

<style scoped lang="scss">
main {
  padding: 56px 15px 80px;
  @media only screen and (max-width: 600px) {
    padding: 48px 24px 56px;
  }

  h1 {
    font-family: 'SamsungSharpSans', sans-serif;
    font-size: 40px;
    font-weight: bold;
    line-height: 1.2;
    color: #000;
    margin-bottom: 56px;
    padding: 0 32px;

    mark {
      background: none;
      color: #00b3e3;
    }

    @media only screen and (max-width: 600px) {
      font-size: 28px;
      margin-bottom: 48px;
    }
  }

  .card__wrap {
    position: relative;
    @media only screen and (max-width: 600px) {
      height: 0;
      padding-bottom: 100%;
    }
  }

  .card {
    width: 440px;
    height: 440px;
    left: 50%;
    top: 50%;
    border-radius: 1.66%;
    //margin: -80px auto;
    @media only screen and (max-width: 600px) {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  .share {
    margin-top: 54px;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 600px) {
      margin-top: 32px;
    }

    a {
      margin: 0 24px;
      @media only screen and (max-width: 600px) {
        margin: 0 20px;
      }
    }

    img {
      width: 48px;
      @media only screen and (max-width: 600px) {
        width: 40px;
      }
    }

    span {
      display: block;
      margin: 6px 0 0;
      font-family: 'SamsungOne', sans-serif;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      color: #333;
      @media only screen and (max-width: 600px) {
        font-size: 13px;
      }
    }
  }

  .download {
    display: block;
    margin-top: 32px;
    width: 173px;
    height: 40px;
    border-radius: 20px;
    background-color: #000;
    color: #fff;
    font-family: 'SamsungOne', sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;
    margin: 34px auto 0;

    @media only screen and (max-width: 600px) {
      margin-top: 48pe;
    }

    img {
      width: 16px;
      vertical-align: text-bottom;
    }
  }
}

footer {
  padding: 64px 32px;
  background-color: #f8f8f8;

  h1 {
    font-family: 'SamsungSharpSans', sans-serif;
    font-size: 40px;
    font-weight: bold;
    line-height: 1.2;
    color: #000;
    @media only screen and (max-width: 600px) {
      font-size: 24px;
    }
  }

  h2 {
    font-family: 'SamsungOne', sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.56;
    color: #000;
    margin-top: 32px;
    @media only screen and (max-width: 600px) {
      font-size: 16px
    }
  }

  p {
    max-width: 1086px;
    margin: 14px auto 0;
    font-family: 'SamsungOne', sans-serif;
    font-size: 18px;
    line-height: 1.44;
    color: #333;
    @media only screen and (max-width: 600px) {
      font-size: 16px;
    }

    strong {
      font-weight: bold;
    }
  }

  a {
    margin-top: 32px;
    width: 115px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    border-radius: 20px;
    background-color: #000;
    font-family: 'SamsungOne', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
  }

  @media only screen and (max-width: 600px) {
    padding: 56px 24px;
  }
}
</style>
