<template>
  <canvas ref="dumpCanvas" width="600" height="600"/>
  <qrcode v-if="!qrLoaded" class="qrcode" :value="`${host}/gotoportal`" :size="112" level="L" :margin="1"
          foreground="#ffffff"
          render-as="svg"
          background="transparent"
          style="position:absolute; left:0; top: 0; z-index:-1"/>
</template>

<script>
import {computed, nextTick, onMounted, ref, watch} from 'vue'
import qrcode from "@/components/qrcode";

export default {
  props: {
    nickname: {default: ''},
    message: {default: ''},
    url: {default: ''},
    blob: {default: ''},
    stickers: {default: []}
  },
  components: {
    qrcode
  },
  setup(props) {
    let host = location.origin
    const nickname = computed(() => props.nickname.trim() || 'SDC Developer')
    const message = computed(() => props.message)
    const blob = computed(() => props.blob)
    const dumpCanvas = ref('')
    const dumpQR = new Image()
    let qrLoaded = false
    onMounted(() => {
      nextTick(async () => {
        const xml = new XMLSerializer().serializeToString(await document.getElementsByClassName('qrcode')[0]);
        const svg = btoa(xml)
        const base64 = 'data:image/svg+xml;base64,'
        dumpQR.onload = function () {
          drawCanvas()
          qrLoaded = true
        }
        dumpQR.src = base64 + svg

      })
    })
    const drawCanvas = () => {
      if (typeof dumpCanvas.value === 'string') return
      const context = dumpCanvas.value.getContext('2d')

      const imageProfile = new Image()
      imageProfile.onload = function () {
        context.drawImage(this, 0, 0, 600, 450)
      }
      imageProfile.src = blob.value

      context.fillStyle = '#1428a0'
      context.fillRect(0, 450, 600, 150)

      context.drawImage(dumpQR, 475, 471, 108, 108)

      context.fillStyle = '#fff'
      context.font = 'bold 40px "SamsungSharpSans"'
      context.fillText(fittingString(context, nickname.value, 436), 25, 515)
      context.font = 'normal 32px "SamsungOne"'
      context.fillText(fittingString(context, message.value, 436), 25, 565)

      function fittingString(context, str, maxWidth) {
        let width = context.measureText(str).width;
        const ellipsis = '…';
        const ellipsisWidth = context.measureText(ellipsis).width;
        if (width <= maxWidth || width <= ellipsisWidth) {
          return str;
        } else {
          let len = str.length;
          while (width >= maxWidth - ellipsisWidth && len-- > 0) {
            str = str.substring(0, len);
            width = context.measureText(str).width;
          }
          return str.trim() + ellipsis;
        }
      }
    }
    watch(nickname, drawCanvas)
    watch(message, drawCanvas)
    return {
      host,
      nickname,
      message,
      blob,
      dumpCanvas,
      drawCanvas,
      qrLoaded
    }
  }
}
</script>

<style scoped lang="scss">

canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transform: translate(0, -50%);
  border-radius: 8px;
  @media only screen and (max-width: 600px) {
    transform: none;
    width: 100%;
    height: 100%;
  }
}

.qrcode {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
}

.card {
  //padding-bottom: 100%;
  //height: 0;
  background-color: #1428a0;
  overflow: hidden;

  figure {
  }

  text {
    shape-outside: url(#text-wrap);
  }

  &__caption {


    &--background {
    }
  }
}
</style>
